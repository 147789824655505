/*
  blu gefran: 005FB6
 */

:root {
  --primary-color: #e44269;
}

.App {
  text-align: center;
  background-color: white;
  height: 100%;
  width: 100%;
}

.auto-margin {
  margin: auto;
}

.header {
  height: 10vw;
  border-bottom: 1px solid gray;
}

.header .logo {
  position: absolute;
  height: 10vw;
  top: 0;
  left: 0;
}

.header .title {
  position: absolute;
  right: 2vw;
  top: 2vw;
  font-size: 7vw;
  line-height: 6vw;
  text-align: left;
  /*border-radius: 1.5vw;*/
  /*background-color: var(--primary-color);*/
  /*color: white;*/
  /*color: #666;*/
  text-transform: uppercase;
  font-weight: bold;
}

/*.header .firstRow {
  font-size: 4.2vw;
  line-height: 4vw;
  background-color: var(--primary-color);
  color: white;
  padding: 1vw;
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
}*/

.body {
  display: flex;
  width: 100%;
  min-height: calc(100% - 10vw);
  text-align: center;
}

.board {
  width: 100%;
  padding: 2vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  display: flex;
}

.question {
  position: relative;
  font-size: 6vw;
  font-weight: bold;
  margin-bottom: 4vh;
  background-color: var(--primary-color);
  color: white;
  padding: 3vw 1vw;
  border-radius: 1vw;
}

.button {
  display: block;
  padding: 2vw;
  /*margin: 1vw;*/
  border-radius: 1vw;
  border: 1px solid darkblue;
  background: var(--primary-color);
  font-size: 6vw;
  color: white;
}

.answer {
  margin: 2vw;
  width: calc(100% - 4vw);
  background-color: #ABCDEF;
}

.bg-light-blue {
  background-color: #ABCDEF;
}

.score {
  margin-top: 4vh;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wrapper .message {
  /*height: 100%;*/
  margin: auto;
  width: calc(100% - 12vw);
  /*padding: 4vw;*/
}

.bottom {
  position: relative;
  width: 100%;
  margin-top: 6vh;
  font-size: 4vw;
}

.all-long {
  width: 100%;
  margin: 2vw;
  width: calc(100% - 4vw);
}

.bottom .button {
  width: 100%;
  margin: 2vw;
  width: calc(100% - 4vw);
}

.message {
  width: 100%;
  font-size: 6vw;
  margin: auto;
}

@media screen and (max-height: 440px) {
  .bottom {
    display: none;
  }
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

/* TODO: aggiungere una media query per visualizzare in landscape un 'gira il telefono per vedere meglio' */