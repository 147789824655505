.home {
    display: flex;
    width: 100%;
}

.register {
    width: 95%;
    margin: 0 auto;
}

.register p {
    display: block;
    margin-bottom: 15vw;
    margin-top: 15vw;
    font-size: 7vw;
}

.register label {
    font-size: 7vw;
}

.register .error {
    font-size: 7vw;
    margin: 5vw 0;
    color: red;
}

.register input {
    width: 100%;
    margin-top: 1vw;
    border: 1px solid darkblue;
    font-size: 7vw;
    padding: 2vw;
    box-sizing: border-box;
}

.register .button {
    width: 100%;
    margin-top: 1vw;
}

.register .privacy {
    text-align: center;
    margin-bottom: 5vw;
}